<template>
  <div>
    <div class="wrapper">
      <comheader :index="3"></comheader>
      <main id="content" role="main">
        <input type="hidden" name="hdnAgeText" id="hdnAgeText" value="Age" />
        <input
          type="hidden"
          name="hdnDateTimeText"
          id="hdnDateTimeText"
          value="Date Time (UTC)"
        />
        <input
          type="hidden"
          name="hdnAgeTitle"
          id="hdnAgeTitle"
          value="Click to show Age Format"
        />
        <input
          type="hidden"
          name="hdnDateTimeTitle"
          id="hdnDateTimeTitle"
          value="Click to show Datetime Format"
        />

        <div class="container">
          <div id="ContentPlaceHolder1_divTokensTitle" class="py-3">
            <div class="mb-1 mb-md-0">
              <h1
                id="ContentPlaceHolder1_h1Tag"
                class="h4 d-flex align-items-center mb-0"
              >
                Token Tracker
              </h1>
            </div>
          </div>
        </div>

        <div class="container space-bottom-2">
          <div class="card">
            <div
              id="ContentPlaceHolder1_searchBarDiv"
              class="
                card-header
                d-md-flex
                justify-content-between
                align-items-center
              "
            >
              <div class="mb-1 mb-md-0">
                <h2 class="card-header-title">PRC-20 Tokens</h2>
              </div>
              <!-- <div class="d-flex ml-auto">
                <div class="position-relative order-1 order-md-2">
                  <a
                    id="searchFilterInvoker"
                    class="btn btn-sm btn-icon btn-primary"
                    href="javascript:;"
                    role="button"
                    aria-controls="searchFilter"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-unfold-target="#searchFilter"
                    data-unfold-type="css-animation"
                    data-unfold-duration="300"
                    data-unfold-delay="300"
                    data-unfold-hide-on-scroll="false"
                    data-unfold-animation-in="slideInUp"
                    data-unfold-animation-out="fadeOut"
                    data-toggle="tooltip"
                    title="Search for Token Name or Address"
                  >
                    <i class="fa fa-search btn-icon__inner"></i>
                  </a>
                  <div
                    id="searchFilter"
                    class="
                      dropdown-menu dropdown-unfold dropdown-menu-sm-right
                      p-2
                    "
                    aria-labelledby="searchFilterInvoker"
                    style="width: 310px"
                  >
                    <form
                      action="/tokens"
                      method="get"
                      autocomplete="off"
                      spellcheck="false"
                      class="js-focus-state input-group input-group-sm w-100"
                    >
                      <input
                        id="searchtoken"
                        name="q"
                        type="search"
                        value=""
                        class="form-control"
                        placeholder="Search for Token Name or Address"
                        onkeyup="handleTokenSearch(this);"
                      />
                      <div class="input-group-append">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          data-toggle="tooltip"
                        >
                          Find
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div> -->
            </div>

            <div class="card-body">
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="transfers"
                  role="tabpanel"
                  aria-labelledby="tokens-tab"
                >
                  <div
                    id="ContentPlaceHolder1_divpagingpanel"
                    class="
                      d-md-flex
                      justify-content-between
                      align-items-center
                      mb-4
                    "
                  >
                    <p class="mb-2 mb-lg-0">
                      <i
                        id="spinwheel"
                        class="fa fa-spin fa-spinner fa-1x fa-pulse"
                        style="display: none; margin-top: 4px"
                        >&nbsp;</i
                      >
                      A total of {{ totalTokens }} Token Contracts found
                    </p>
                    <nav class="mb-4 mb-md-0" aria-label="page navigation">
                      <ul class="pagination pagination-sm mb-0">
                        <li class="page-item" :class="{ disabled: page == 0 }">
                          <span
                            class="page-link"
                            @click="
                              page = 0;
                              changePage();
                            "
                            >First</span
                          >
                        </li>

                        <li class="page-item" :class="{ disabled: page == 0 }">
                          <span
                            @click="
                              page = page - 1;
                              changePage();
                            "
                            class="page-link"
                            ><i class="fa fa-chevron-left small"></i></span
                          ><span class="sr-only">Previous</span>
                        </li>
                        <li class="page-item">
                          <span class="page-link text-nowrap"
                            >Page
                            <strong class="font-weight-medium">{{
                              page + 1
                            }}</strong>
                            of
                            <strong class="font-weight-medium">{{
                              allPages
                            }}</strong></span
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: page + 1 == allPages }"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Go to Next"
                        >
                          <span
                            class="page-link"
                            @click="
                              page = page + 1;
                              changePage();
                            "
                            aria-label="Next"
                            ><span aria-hidden="True"
                              ><i class="fa fa-chevron-right small"></i
                            ></span>
                            <span class="sr-only">Next</span></span
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: page + 1 == allPages }"
                        >
                          <span
                            @click="
                              page = allPages - 1;
                              changePage();
                            "
                            class="page-link"
                            ><span aria-hidden="True">Last</span>
                            <span class="sr-only">Last</span></span
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div class="js-sticky-header" id="divSTContainer">
                    <div
                      id="ContentPlaceHolder1_divresult"
                      class="table-responsive mb-2 mb-md-0"
                    >
                      <table
                        class="table table-text-normal table-hover"
                        id="tblResult"
                      >
                        <thead class="thead-light">
                          <tr>
                            <th
                              scope="col"
                              width="1"
                              class="token-sticky-header"
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              class="token-sticky-header"
                              style="width: 272px"
                            >
                              Token
                            </th>
                            <th scope="col">
                              <div class="d-flex align-items-center">
                                <div class="mr-2">Age</div>
                              </div>
                            </th>
                            <th scope="col">
                              <div class="d-flex align-items-center">
                                <div class="mr-2">Decimal</div>
                              </div>
                            </th>
                            <th scope="col">
                              <div class="d-flex align-items-center">
                                <div class="mr-2">Max Total Supply</div>
                              </div>
                            </th>
                            <th scope="col">
                              <div class="d-flex align-items-center">
                                <div class="mr-2">Creator</div>
                              </div>
                            </th>
                            <th scope="col">
                              <div class="d-flex align-items-center">
                                <div class="mr-2">TxHash</div>
                              </div>
                            </th>
                            <!-- <th scope="col">
                              <div class="d-flex align-items-center">
                                <div class="mr-2">Status</div>
                              </div>
                            </th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in tokenList" :key="index">
                            <td>{{ per_page * page + index + 1 }}</td>
                            <td>
                              <div class="media">
                                <img
                                  class="u-xs-avatar mr-2"
                                  :src="item.imageurl"
                                />
                                <div class="media-body">
                                  <h3 class="h6 mb-0">
                                    <a
                                      class="text-primary"
                                      :href="'/txs?address=' + item.address"
                                      >{{ item.name }}</a
                                    >
                                  </h3>
                                  <p class="d-none d-md-block font-size-1 mb-0">
                                    {{ item.info }}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td style="" class="showAge">
                              <span
                                rel="tooltip"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title=""
                                data-original-title=""
                                >{{ item.createdAt | timeFromat }}</span
                              >
                            </td>
                            <td class="text-nowrap">
                              {{ item.decimal }}
                            </td>
                            <td>
                              <span class="text-success text-truncate">{{
                                item.totalSupply | formatNum
                              }}</span>
                            </td>
                            <td>
                              <span class="hash-tag text-truncate"
                                ><a
                                  :href="'/txs?address=' + item.creator"
                                  class="myFnExpandBox_searchVal"
                                  >{{ item.creator }}</a
                                ></span
                              >
                            </td>
                            <td>
                              <span class="hash-tag text-truncate"
                                ><a
                                  :href="
                                    '/tx/' + item.txHash + '/' + item.height
                                  "
                                  class="myFnExpandBox_searchVal"
                                  >{{ item.txHash }}</a
                                ></span
                              >
                            </td>
                            <!-- <td>
                              {{ item.status }}
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <form method="post" action="./tokens" id="ctl00">
                    <div class="aspNetHidden">
                      <input
                        type="hidden"
                        name="__EVENTTARGET"
                        id="__EVENTTARGET"
                        value=""
                      />
                      <input
                        type="hidden"
                        name="__EVENTARGUMENT"
                        id="__EVENTARGUMENT"
                        value=""
                      />
                      <input
                        type="hidden"
                        name="__LASTFOCUS"
                        id="__LASTFOCUS"
                        value=""
                      />
                      <input
                        type="hidden"
                        name="__VIEWSTATE"
                        id="__VIEWSTATE"
                        value="emvtAdRAedcOKScwWLosj7/9g3n277BUjmCZBLmbnZXAjUZ1qgQ4POfgJghGSeCvTn8x9wXm9TnE9OJxdZ/aKmYw2IMZSgngk6/Jz4KFzck="
                      />
                    </div>

                    <div class="aspNetHidden">
                      <input
                        type="hidden"
                        name="__VIEWSTATEGENERATOR"
                        id="__VIEWSTATEGENERATOR"
                        value="3C86B011"
                      />
                      <input
                        type="hidden"
                        name="__EVENTVALIDATION"
                        id="__EVENTVALIDATION"
                        value="b4Pp8mIs9sCgVSVny4ReD61XPtnIryQL9ZoWX8qhjVzYoIuVuqkMAiiML+fsO7sc3NtV6Wf15SDoC3fTq3ha5y11x9ADo9yBo+RMNBetJLWif8k90Lb56WD/B26WiVs3VoBw7eNueqFwn50qf2fIimC3Wco4m/BgpY83jccjfrT5BvV+6x+GVRCGI2ETl3OnMVb5jKiwyn7b2vEVqrGoDQ=="
                      />
                    </div>
                    <div
                      id="ContentPlaceHolder1_divPagination"
                      class="d-md-flex justify-content-between my-3"
                    >
                      <div
                        class="
                          d-flex
                          align-items-center
                          text-secondary
                          mb-2 mb-md-0
                        "
                      >
                        Show
                        <select
                          name="ctl00$ContentPlaceHolder1$ddlRecordsPerPage"
                          v-model="per_page"
                          @change="changePerPage"
                          id="ContentPlaceHolder1_ddlRecordsPerPage"
                          class="custom-select custom-select-xs mx-2"
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option selected="selected" value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Records
                      </div>
                      <ul class="pagination pagination-sm mb-0">
                        <li class="page-item" :class="{ disabled: page == 0 }">
                          <span
                            class="page-link"
                            @click="
                              page = 0;
                              changePage();
                            "
                            >First</span
                          >
                        </li>

                        <li class="page-item" :class="{ disabled: page == 0 }">
                          <span
                            @click="
                              page = page - 1;
                              changePage();
                            "
                            class="page-link"
                            ><i class="fa fa-chevron-left small"></i></span
                          ><span class="sr-only">Previous</span>
                        </li>
                        <li class="page-item">
                          <span class="page-link text-nowrap"
                            >Page
                            <strong class="font-weight-medium">{{
                              page + 1
                            }}</strong>
                            of
                            <strong class="font-weight-medium">{{
                              allPages
                            }}</strong></span
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: page + 1 == allPages }"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Go to Next"
                        >
                          <span
                            class="page-link"
                            @click="
                              page = page + 1;
                              changePage();
                            "
                            aria-label="Next"
                            ><span aria-hidden="True"
                              ><i class="fa fa-chevron-right small"></i
                            ></span>
                            <span class="sr-only">Next</span></span
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: page + 1 == allPages }"
                        >
                          <span
                            @click="
                              page = allPages - 1;
                              changePage();
                            "
                            class="page-link"
                            ><span aria-hidden="True">Last</span>
                            <span class="sr-only">Last</span></span
                          >
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            id="js-column_freeze_id"
            class="
              d-sm-none d-flex
              justify-content-between
              align-items-center
              mt-3
            "
          >
            <p class="mb-0">Turn Column Freeze on/off:</p>
            <div id="js-column_freeze_id_inner">
              <div class="js-mainbtn custom-control custom-switch mt-n3">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="js-customSwitch1"
                />
                <label
                  class="custom-control-label"
                  for="js-customSwitch1"
                ></label>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div id="push"></div>
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        id="emailSubscribeModalBox"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close close-md"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div class="text-center py-5-alt px-4">
                <div id="emailSubscribeModalBoxText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <comfooter></comfooter>
  </div>
</template>
<script>
import comheader from "@/components/serach_header";
import comfooter from "@/components/comfooter";
import { timeFrom } from "@/Utils/timeFrom";
import { get_token_list } from "@/API/api";
import { prc20tokens } from "../contract/prc20tokens";
import { ethers } from "ethers";
import contractAddress from "@/contract/address";
import prctokens from "../contract/tokens.json";
import USDTURL from "../assets/images/USDT.png";
import WDCURL from "../assets/images/WDC.png";
import WPSCURL from "../assets/images/WPSC.png";
import CATURL from "../assets/images/CAT.png";
export default {
  data() {
    return {
      usdtimg: USDTURL,
      wdcimg: WDCURL,
      wpscimg: WPSCURL,
      catimg: CATURL,
      tokenList: [
      
      ],
      page: 0,
      per_page: 50,
      totalTokens: 0,
      allPages: 0,
      firstToken: 0,
      endToken: 0,
    };
  },
  components: {
    comheader,
    comfooter,
  },
  async mounted() {
    let that = this;

    that.get_token_list_new();
  },
  methods: {
    get_token_list() {
      let that = this;
      get_token_list(that.per_page, that.page).then((res) => {
        that.tokenList = res.data.content;
        that.totalTokens = res.data.totalElements;
        that.allPages = res.data.totalPages;
        if (that.tokenList.length > 0) {
          that.firstToken = that.tokenList[0];
          that.endToken = that.tokenList[that.tokenList.length - 1];
        }
      });
    },
    get_token_list_new() {
      let that = this;
      that.tokenList = prc20tokens;
      that.totalTokens = 3;
      that.allPages = 1;
      if (that.tokenList.length > 0) {
        that.firstToken = that.tokenList[0];
        that.endToken = that.tokenList[that.tokenList.length - 1];
      }
      that.tokenList[0].imageurl = that.usdtimg;
      that.tokenList[0].totalSupply = localStorage.getItem('usdtTotal')
      that.tokenList[1].imageurl = that.wpscimg;
      that.tokenList[2].imageurl = that.wdcimg;
      that.tokenList[3].imageurl = that.catimg;
      that.tokenList[3].totalSupply = localStorage.getItem('catTotal')
      that.seeUSDTTotal(); 
    },
    async seeUSDTTotal() {
      let that = this;

      let contract = that.getContractERC(prctokens.mainUSDT);
      let res = await contract.totalSupply();

      that.tokenList[0].totalSupply = ethers.utils.formatUnits(
        res.toString(),
        6
      );
      localStorage.setItem("usdtTotal", that.tokenList[0].totalSupply);
    }, 
    getContractERC(address) {
      const provider = new ethers.providers.JsonRpcProvider(
        contractAddress.mainrpc
      );
      // let provider = getProvider();
      let ercabi = [
        {
          inputs: [],
          name: "totalSupply",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "tokenOwner",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              internalType: "uint256",
              name: "balance",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "decimals",
          outputs: [
            {
              internalType: "uint8",
              name: "",
              type: "uint8",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "symbol",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
      ];
      let contract = new ethers.Contract(address, ercabi, provider);
      return contract;
    },
    changePerPage() {
      let that = this;
      that.page = 0;
      that.get_token_list_new();
    },
    changePage() {
      let that = this;
      that.get_token_list_new();
    },
  },
  filters: {
    timeFromat(date) {
      return timeFrom(date);
    },
    formatNum(num) {
      if (!/^(-|\+)?(\d+)(\.\d+)?$/.test(num)) {
        return num;
      }
      var sign = RegExp.$1;
      var middle = RegExp.$2;
      var decimals = RegExp.$3;

      var reg = /(\d)(\d{3})(,|$)/;
      while (reg.test(middle)) {
        middle = middle.replace(reg, "$1,$2$3");
      }
      return sign + "" + middle + "" + decimals;
    },
  },
};
</script>
